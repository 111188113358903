<template>
  <div class="d-flex align-center py-4">
    <span class="mx-3 mx-6"> {{ index + 1 }} </span>
    <v-textarea
      v-if="edit"
      class="instruction-input"
      v-model="instruction"
      :hide-details="!inputErrors.length"
      :error-messages="inputErrors"
      @input="onInstructionChanged"
      rows="3"
      auto-grow
      outlined
    />

    <div v-else class="full-width">
      {{ instruction }}
    </div>

    <div class="ml-3">
      <slot name="remove-button"> </slot>
    </div>

    <div class="mr-3">
      <slot name="drag-button"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExerciseInstruction',

  props: {
    instructionData: {
      required: true,
    },
    index: {
      type: Number,
    },
    edit: {
      type: Boolean,
      default: false,
      required: true,
    },
    errors: {
      type: Array,
    },
  },

  data() {
    return {
      instruction: this.instructionData,
    }
  },

  created() {
    this.instruction = this.instructionData
  },

  computed: {
    inputErrors() {
      if (!this.instruction) {
        return ['instructiion cannot be empty']
      }

      return []
    },
  },

  methods: {
    onInstructionChanged(value) {
      this.$emit('instruction-changed', {
        index: this.index,
        step: value,
      })
    },
  },

  watch: {
    instructionData() {
      this.instruction = this.instructionData
    },
  },
}
</script>
