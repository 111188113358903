var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bbr-workouts--circuit-exercises"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.isLissCardio)?_c('div',{staticClass:"d-flex align-center"},[(!_vm.isLissCardio)?_c('v-checkbox',{attrs:{"label":"Is Workout?","value":1,"color":"primary"},model:{value:(_vm.exercise.type),callback:function ($$v) {_vm.$set(_vm.exercise, "type", $$v)},expression:"exercise.type"}}):_vm._e(),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"secondary","text":""},on:{"click":_vm.removeExercise}},[_vm._v(" Delete Exercise ")])],1):_vm._e()]),_c('v-col',{attrs:{"cols":"12","sm":_vm.isLissCardio ? 8 : 6}},[_c('search-exercise',{attrs:{"default-value":_vm.exercise.data,"excluded-items":_vm.selectedExercises,"errors":_vm.request.$getError(
            ("circuits." + _vm.circuitIndex + ".exercises." + _vm.exerciseIndex + ".exercise_id")
          ),"trainer":_vm.workout.trainer,"label":"Exercise Name","with-equipments":"","hide-details":"","with-trainer":"","completed":"","outlined":"","flat":""},model:{value:(_vm.exercise.exercise_id),callback:function ($$v) {_vm.$set(_vm.exercise, "exercise_id", $$v)},expression:"exercise.exercise_id"}})],1),(_vm.isLissCardio)?_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"secondary","text":""},on:{"click":_vm.removeExercise}},[_vm._v(" Delete Exercise ")])],1):_vm._e(),(!_vm.isLissCardio)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('XXXX'),expression:"'XXXX'"}],attrs:{"label":"Tempo","hide-details":"","outlined":"","flat":""},on:{"input":function($event){_vm.exercise.tempo = _vm.exercise.tempo.toUpperCase()}},model:{value:(_vm.exercise.tempo),callback:function ($$v) {_vm.$set(_vm.exercise, "tempo", $$v)},expression:"exercise.tempo"}})],1):_vm._e(),(!_vm.isLissCardio)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"label":"Rest","error-messages":_vm.request.$getError(
            ("circuits." + _vm.circuitIndex + ".exercises." + _vm.exerciseIndex + ".rest")
          ),"hide-details":!_vm.request.$getError(
            ("circuits." + _vm.circuitIndex + ".exercises." + _vm.exerciseIndex + ".rest")
          ),"outlined":"","flat":""},model:{value:(_vm.exercise.rest),callback:function ($$v) {_vm.$set(_vm.exercise, "rest", $$v)},expression:"exercise.rest"}})],1):_vm._e()],1),_c('v-row',[(_vm.isWorkout)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.setType,"label":"Set Type","item-text":"name","item-value":"id","hide-details":"","clearable":"","outlined":"","flat":""},model:{value:(_vm.exercise.set_type),callback:function ($$v) {_vm.$set(_vm.exercise, "set_type", $$v)},expression:"exercise.set_type"}})],1):_vm._e(),(_vm.isWorkout)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"label":"Reps","error-messages":_vm.request.$getError(
            ("circuits." + _vm.circuitIndex + ".exercises." + _vm.exerciseIndex + ".reps")
          ),"hide-details":!_vm.request.$getError(
            ("circuits." + _vm.circuitIndex + ".exercises." + _vm.exerciseIndex + ".reps")
          ),"outlined":"","flat":""},model:{value:(_vm.exercise.reps),callback:function ($$v) {_vm.$set(_vm.exercise, "reps", $$v)},expression:"exercise.reps"}})],1):_vm._e(),(_vm.isWorkout)?_c('v-col',{attrs:{"cols":"12","sm":3}},[_c('v-select',{attrs:{"items":_vm.switchTypes,"label":"Switch Type","item-text":"name","item-value":"id","hide-details":"","clearable":"","outlined":"","flat":""},model:{value:(_vm.exercise.switch_type),callback:function ($$v) {_vm.$set(_vm.exercise, "switch_type", $$v)},expression:"exercise.switch_type"}})],1):_vm._e(),(_vm.isWorkout)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"label":"Sets","error-messages":_vm.request.$getError(
            ("circuits." + _vm.circuitIndex + ".exercises." + _vm.exerciseIndex + ".sets")
          ),"hide-details":!_vm.request.$getError(
            ("circuits." + _vm.circuitIndex + ".exercises." + _vm.exerciseIndex + ".sets")
          ),"outlined":"","flat":""},model:{value:(_vm.exercise.sets),callback:function ($$v) {_vm.$set(_vm.exercise, "sets", $$v)},expression:"exercise.sets"}})],1):_vm._e(),(!_vm.isWorkout && !_vm.isLissCardio)?_c('v-col',{attrs:{"cols":"12","sm":6}},[_c('v-select',{attrs:{"items":_vm.switchTypes,"label":"Switch Type","item-text":"name","item-value":"id","hide-details":"","clearable":"","outlined":"","flat":""},model:{value:(_vm.exercise.switch_type),callback:function ($$v) {_vm.$set(_vm.exercise, "switch_type", $$v)},expression:"exercise.switch_type"}})],1):_vm._e(),(!_vm.isWorkout && !_vm.isLissCardio)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"label":"Rounds","error-messages":_vm.request.$getError(
            ("circuits." + _vm.circuitIndex + ".exercises." + _vm.exerciseIndex + ".rounds")
          ),"hide-details":!_vm.request.$getError(
            ("circuits." + _vm.circuitIndex + ".exercises." + _vm.exerciseIndex + ".rounds")
          ),"outlined":"","flat":""},model:{value:(_vm.exercise.rounds),callback:function ($$v) {_vm.$set(_vm.exercise, "rounds", $$v)},expression:"exercise.rounds"}})],1):_vm._e(),(!_vm.isWorkout && !_vm.isLissCardio)?_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"label":"Duration (seconds)","error-messages":_vm.request.$getError(
            ("circuits." + _vm.circuitIndex + ".exercises." + _vm.exerciseIndex + ".duration")
          ),"hide-details":!_vm.request.$getError(
            ("circuits." + _vm.circuitIndex + ".exercises." + _vm.exerciseIndex + ".duration")
          ),"outlined":"","flat":""},model:{value:(_vm.exercise.duration),callback:function ($$v) {_vm.$set(_vm.exercise, "duration", $$v)},expression:"exercise.duration"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.orderItems,"label":"Order","error-messages":_vm.request.$getError(
            ("circuits." + _vm.circuitIndex + ".exercises." + _vm.exerciseIndex + ".order")
          ),"hide-details":!_vm.request.$getError(
            ("circuits." + _vm.circuitIndex + ".exercises." + _vm.exerciseIndex + ".order")
          ),"hide-selected":"","outlined":"","flat":""},model:{value:(_vm.exercise.order),callback:function ($$v) {_vm.$set(_vm.exercise, "order", $$v)},expression:"exercise.order"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }