<template>
  <div class="bbr-workout--details-form">
    <v-row>
      <v-col cols="12" sm="3">
        <v-checkbox
          v-model="form.is_liss"
          label="Is LISS Cardio"
          color="primary"
        />
      </v-col>

      <v-col cols="12" sm="4">
        <v-checkbox
          v-model="form.is_workout_extra"
          label="Save as workout extra"
          color="primary"
        />
      </v-col>

      <v-col v-if="form.is_workout_extra" cols="12" sm="4">
        <v-checkbox
          v-model="form.has_difficulty"
          label="Has difficulty"
          color="primary"
        />
      </v-col>

      <v-col cols="12">
        <v-combobox
          v-model="form.tags"
          :hide-no-data="!search"
          :search-input.sync="search"
          :label="`${asWorkoutExtraLabel} Tags`"
          hide-selected
          small-chips
          multiple
          outlined
          flat
        >
          <template v-slot:no-data>
            <v-list-item>
              <span class="subheading">Create</span>
              <v-chip class="mx-2" color="primary" small>
                {{ search }}
              </v-chip>
              <span> as a new tag </span>
              <span> . Press <kbd>enter</kbd> to create a new one </span>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>

      <v-col cols="12" sm="8">
        <v-text-field
          v-model="form.title"
          :label="`${asWorkoutExtraLabel} Title`"
          :error-messages="form.$getError('title')"
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="4">
        <search-trainer-input
          v-model="form.trainer_id"
          label="Workout Trainer"
          :default-value="form.trainer"
          :error-messages="form.$getError('trainer_id')"
          clearable
          outlined
          flat
        />
      </v-col>

      <v-col cols="12">
        <training-program-search-input
          label="Training Programs"
          :error-messages="form.$getError('training_program_ids')"
          v-model="form.training_programs"
          hide-selected
          small-chips
          multiple
          outlined
          flat
        />
      </v-col>

      <v-col
        v-for="(duration, index) in form.durations"
        :key="index"
        cols="12"
        sm="6"
      >
        <v-text-field
          v-model="form.durations[index].duration"
          :label="`${duration.training_program.name} Duration`"
          outlined
          flat
        />
      </v-col>

      <v-col v-if="form.is_workout_extra" cols="12" sm="6">
        <v-select
          v-model="form.type"
          :items="workoutExtraTypes"
          :label="`${asWorkoutExtraLabel} Type`"
          :error-messages="form.$getError('type')"
          item-text="name"
          item-value="id"
          clearable
          outlined
          flat
        />
      </v-col>

      <v-col v-if="form.is_workout_extra" cols="12" sm="6">
        <v-select
          v-model="form.category"
          :items="workoutExtraSubTypes(form.type)"
          :label="`${asWorkoutExtraLabel} Sub-type`"
          :error-messages="form.$getError('category')"
          item-text="name"
          item-value="id"
          clearable
          outlined
          flat
        />
      </v-col>

      <v-col cols="12">
        <v-textarea
          v-model="form.description"
          :label="`${asWorkoutExtraLabel} Description`"
          :error-messages="form.$getError('description')"
          rows="4"
          auto-grow
          no-resize
          outlined
          flat
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TrainingProgramSearchInput from '@/components/elements/TrainingProgramSearchInput'
import SearchTrainerInput from '@/components/elements/trainers/TrainerSearchInput'
import Form from '@/utils/form'
import { map } from 'lodash'

import { mapGetters, mapActions } from 'vuex'
import { mdiStar } from '@mdi/js'

export default {
  name: 'WorkoutDetailsForm',

  components: {
    SearchTrainerInput,
    TrainingProgramSearchInput,
  },

  props: {
    formData: {
      type: Form,
      required: true,
    },
  },

  data() {
    return {
      search: null,
      form: this.formData,
      icons: {
        star: mdiStar,
      },
    }
  },

  async created() {
    await this.getWorkoutExtraCategories()
  },

  methods: {
    ...mapActions({
      getWorkoutExtraCategories: 'workouts/getWorkoutExtraCategories',
    }),
  },

  computed: {
    ...mapGetters({
      workoutExtraTypes: 'workouts/getWorkoutExtraTypes',
      workoutExtraSubTypes: 'workouts/getWorkoutExtraSubTypes',
    }),

    asWorkoutExtraLabel() {
      return this.form.is_workout_extra ? 'Workout Extra' : 'Workout'
    },
  },

  mounted() {
    this.form.training_program_ids = map(this.form.training_programs, 'id')
  },

  watch: {
    formData() {
      this.form = this.formData
    },

    'formData.is_workout_extra'(value) {
      if (!value) {
        this.form.type = null
        this.form.category = null
        this.form.difficulty = 1
      }
    },

    'form.training_programs'(value) {
      this.form.training_program_ids = map(value, 'id')
    },
  },
}
</script>

<style lang="scss">
.v-difficulty-rating {
  .v-icon {
    padding: 0 !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
}
</style>
