<template>
  <div class="bbr-workouts--circuit-exercises">
    <v-row>
      <v-col cols="12">
        <div v-if="!isLissCardio" class="d-flex align-center">
          <v-checkbox
            v-if="!isLissCardio"
            v-model="exercise.type"
            label="Is Workout?"
            :value="1"
            color="primary"
          />

          <v-btn class="ml-auto" color="secondary" @click="removeExercise" text>
            Delete Exercise
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12" :sm="isLissCardio ? 8 : 6">
        <search-exercise
          v-model="exercise.exercise_id"
          :default-value="exercise.data"
          :excluded-items="selectedExercises"
          :errors="
            request.$getError(
              `circuits.${circuitIndex}.exercises.${exerciseIndex}.exercise_id`
            )
          "
          :trainer="workout.trainer"
          label="Exercise Name"
          with-equipments
          hide-details
          with-trainer
          completed
          outlined
          flat
        />
      </v-col>

      <v-col v-if="isLissCardio" class="d-flex align-center" cols="12" sm="4">
        <v-btn class="ml-auto" color="secondary" @click="removeExercise" text>
          Delete Exercise
        </v-btn>
      </v-col>

      <v-col v-if="!isLissCardio" cols="12" sm="3">
        <v-text-field
          v-model="exercise.tempo"
          v-mask="'XXXX'"
          @input="exercise.tempo = exercise.tempo.toUpperCase()"
          label="Tempo"
          hide-details
          outlined
          flat
        />
      </v-col>

      <v-col v-if="!isLissCardio" cols="12" sm="3">
        <v-text-field
          v-model="exercise.rest"
          v-mask="'####'"
          label="Rest"
          :error-messages="
            request.$getError(
              `circuits.${circuitIndex}.exercises.${exerciseIndex}.rest`
            )
          "
          :hide-details="
            !request.$getError(
              `circuits.${circuitIndex}.exercises.${exerciseIndex}.rest`
            )
          "
          outlined
          flat
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="isWorkout" cols="12" sm="3">
        <v-select
          v-model="exercise.set_type"
          :items="setType"
          label="Set Type"
          item-text="name"
          item-value="id"
          hide-details
          clearable
          outlined
          flat
        />
      </v-col>

      <v-col v-if="isWorkout" cols="12" sm="3">
        <v-text-field
          v-model="exercise.reps"
          label="Reps"
          :error-messages="
            request.$getError(
              `circuits.${circuitIndex}.exercises.${exerciseIndex}.reps`
            )
          "
          :hide-details="
            !request.$getError(
              `circuits.${circuitIndex}.exercises.${exerciseIndex}.reps`
            )
          "
          outlined
          flat
        />
      </v-col>

      <v-col v-if="isWorkout" cols="12" :sm="3">
        <v-select
          v-model="exercise.switch_type"
          :items="switchTypes"
          label="Switch Type"
          item-text="name"
          item-value="id"
          hide-details
          clearable
          outlined
          flat
        />
      </v-col>

      <v-col v-if="isWorkout" cols="12" sm="3">
        <v-text-field
          v-model="exercise.sets"
          v-mask="'####'"
          label="Sets"
          :error-messages="
            request.$getError(
              `circuits.${circuitIndex}.exercises.${exerciseIndex}.sets`
            )
          "
          :hide-details="
            !request.$getError(
              `circuits.${circuitIndex}.exercises.${exerciseIndex}.sets`
            )
          "
          outlined
          flat
        />
      </v-col>

      <v-col v-if="!isWorkout && !isLissCardio" cols="12" :sm="6">
        <v-select
          v-model="exercise.switch_type"
          :items="switchTypes"
          label="Switch Type"
          item-text="name"
          item-value="id"
          hide-details
          clearable
          outlined
          flat
        />
      </v-col>

      <v-col v-if="!isWorkout && !isLissCardio" cols="12" sm="3">
        <v-text-field
          v-model="exercise.rounds"
          v-mask="'####'"
          label="Rounds"
          :error-messages="
            request.$getError(
              `circuits.${circuitIndex}.exercises.${exerciseIndex}.rounds`
            )
          "
          :hide-details="
            !request.$getError(
              `circuits.${circuitIndex}.exercises.${exerciseIndex}.rounds`
            )
          "
          outlined
          flat
        />
      </v-col>

      <v-col v-if="!isWorkout && !isLissCardio" cols="12" sm="3">
        <v-text-field
          v-model="exercise.duration"
          v-mask="'####'"
          label="Duration (seconds)"
          :error-messages="
            request.$getError(
              `circuits.${circuitIndex}.exercises.${exerciseIndex}.duration`
            )
          "
          :hide-details="
            !request.$getError(
              `circuits.${circuitIndex}.exercises.${exerciseIndex}.duration`
            )
          "
          outlined
          flat
        />
      </v-col>

      <v-col cols="12" sm="3">
        <v-select
          :items="orderItems"
          label="Order"
          v-model="exercise.order"
          :error-messages="
            request.$getError(
              `circuits.${circuitIndex}.exercises.${exerciseIndex}.order`
            )
          "
          :hide-details="
            !request.$getError(
              `circuits.${circuitIndex}.exercises.${exerciseIndex}.order`
            )
          "
          hide-selected
          outlined
          flat
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SearchExercise from '@/components/elements/exercises/SearchExercisesInput'
import Form from '@/utils/form'
import range from 'lodash/range'

import { mask } from 'vue-the-mask'
import { mapState } from 'vuex'

export default {
  name: 'CircuitExercise',

  components: {
    SearchExercise,
  },

  directives: {
    mask,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    selectedExercises: {
      type: Array,
    },
    request: {
      type: Form,
    },
    circuitIndex: {
      type: Number,
    },
    exerciseIndex: {
      type: Number,
    },
  },

  data() {
    return {
      switchTypes: [
        { id: 1, name: 'Each Arm' },
        { id: 2, name: 'Each Leg' },
        { id: 3, name: 'Each Side' },
        { id: 4, name: 'E/W' },
      ],
      setType: [
        { id: 1, name: 'Drop Set' },
        { id: 2, name: 'Pyramid Set' },
        { id: 3, name: 'Full Pyramid Set' },
        { id: 4, name: 'Wave Load' },
      ],
      exerciseType: [
        { id: 1, name: 'Workout' },
        { id: 2, name: 'Circuit' },
      ],
      exercise: {
        type: 1,
        name: null,
        rest: null,
        sets: null,
        reps: null,
        tempo: null,
        rounds: null,
        duration: null,
        set_type: null,
        switch_type: null,
        order: null,
      },
    }
  },

  computed: {
    ...mapState({
      workout: (state) => state.workouts.selectedWorkout,
    }),

    isLissCardio() {
      return this.workout.is_liss
    },

    isWorkout() {
      return this.exercise.type === 1
    },
    orderItems() {
      return range(1, this.selectedExercises.length + 1)
    },
  },

  created() {
    this.exercise = this.item
  },

  methods: {
    removeExercise() {
      this.$emit('delete')
    },
  },

  watch: {
    item(value) {
      this.exercise = value
    },

    exercise: {
      deep: true,
      handler() {
        this.$emit('change')
      },
    },

    'exercise.switch_type'(val) {
      if (!val) {
        this.exercise.switch_type = null
      }
    },

    'exercise.set_type'(val) {
      if (!val) {
        this.exercise.set_type = null
      }
    },

    'exercise.type': {
      deep: true,
      handler(exericseType) {
        if (exericseType === 1) {
          this.exercise.type = 1
          this.exercise.rounds = 0
          this.exercise.seconds = null
          this.exercise.duration = null
        } else {
          this.exercise.type = 2
          this.exercise.sets = null
          this.exercise.reps = null
        }
      },
    },
    'exercise.order'(newOrder, oldOrder) {
      this.$emit('changeOrder', {
        newOrder,
        oldOrder,
        index: this.exerciseIndex,
      })
    },
  },
}
</script>
