<template>
  <div class="bbr-workouts--new-workout-circuits mb-12 pb-12">
    <v-row>
      <v-col lg="9" md="12">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto text-truncate">Workout Exercises</h2>

          <v-btn
            v-if="!workout.completed_at"
            class="mr-5 px-7"
            color="primary"
            @click="saveForLAter"
            :loading="request.$busy"
            text
          >
            <span>Save & Finish Later</span>
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            @click="completeWorkout"
            :loading="request.$busy"
            depressed
            text
          >
            <span v-if="!workout.completed_at">
              Complete Workout
              <v-icon> {{ icons.next }} </v-icon>
            </span>

            <span v-else>
              Save Workout
            </span>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="9" md="12">
        <v-tabs v-model="venue">
          <v-tab
            v-for="trainingProgram in workout.training_programs"
            :key="trainingProgram.id"
          >
            {{ trainingProgram.name }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col lg="9" md="12">
        <v-card class="mt-5 px-5" outlined>
          <workout-circuit-card
            :items="workoutCircuits"
            :request="request"
            :venue="actualVenue"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col lg="9" md="12">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="mr-auto"></div>
          <v-btn
            v-if="!workout.completed_at"
            :loading="request.$busy"
            class="mr-5"
            color="primary"
            @click="saveForLAter"
            text
          >
            <span>Save & Finish Later</span>
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            @click="completeWorkout"
            :loading="request.$busy"
            depressed
            text
          >
            <span v-if="!workout.completed_at"> Complete Workout </span>
            <span v-else> Save Workout </span>
            <v-icon> {{ icons.check }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.open"
      :timeout="2000"
      bottom
      right
    >
      {{ snackbar.msg }}
      <v-btn class="px-5" @click="snackbar.open = false" white>
        <span class="red--text"> Close </span>
      </v-btn>
    </v-snackbar>

    <v-toast ref="toast" />
    <confirm-dialog ref="confirmZeroEstimatedDuration" />
  </div>
</template>

<script>
import WorkoutCircuitCard from '@/components/forms/workouts/Circuits'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import VToast from '@/components/elements/Toast'
import Form from '@/utils/form'

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { mdiArrowRight } from '@mdi/js'

export default {
  name: 'WorkoutCircuits',

  components: {
    VToast,
    WorkoutCircuitCard,
    ConfirmDialog,
  },

  data() {
    return {
      venue: 0,
      request: new Form(),
      equipments: [],
      icons: {
        next: mdiArrowRight,
      },
      snackbar: {
        msg: null,
        open: false,
        color: null,
      },
    }
  },

  computed: {
    ...mapGetters({
      workoutCircuits: 'workouts/getSelectedWorkoutCircuits',
    }),

    ...mapState({
      workout: (state) => state.workouts.selectedWorkout,
    }),

    isLissCardioWorkout() {
      return this.workout.is_liss
    },

    hasCircuits() {
      return this.workoutCircuits.length
    },

    actualVenue() {
      if (this.workout.training_programs.length) {
        const trainingProgram = this.workout.training_programs[this.venue]
        if (trainingProgram) {
          return trainingProgram.id
        }
      }
      return 0
    },

    isWorkoutCompleted() {
      return !!this.workout.completed_at
    },
  },

  created() {
    this.equipments = this.workout.equipments
  },

  methods: {
    ...mapActions({
      saveWorkout: 'workouts/saveWorkout',
      saveCircuits: 'workouts/saveWorkoutCircuitMultiple',
      updateCircuits: 'workouts/updateWorkoutCircuitMultiple',
    }),

    ...mapMutations({
      updateWorkoutList: 'workouts/updateWorkoutList',
    }),

    async confirmForZeroEstimateDuration() {
      const hasZeroEstimatedDuration = !!this.workoutCircuits.find(
        (workoutCircuit) => workoutCircuit.estimated_duration === 0
      )

      return (
        !hasZeroEstimatedDuration ||
        this.$refs.confirmZeroEstimatedDuration.open(
          'Zero Estimated Duration',
          `You have a circuit with zero estimated duration. <br>
          <b> Do you wish to continue? <b>`
        )
      )
    },

    async saveForLAter() {
      this.request.$busy = true
      this.request.$clearErrors()

      let saveOrUpdate = !this.workout.id
        ? this.saveWorkoutCircuits()
        : this.updateWorkoutCircuits()

      try {
        await saveOrUpdate

        this.updateWorkoutList({ complete: false, workout: this.workout })

        this.request.$timeout(() => {
          this.$router.replace({
            name: 'draft.workouts',
          })
        })
      } catch ({ response }) {
        this.request.$timeout(() => {
          if (response && response.status === 422) {
            this.request.$setErrors(response.data.errors)

            this.$refs.toast.open({
              color: 'red',
              message:
                response.data.message ??
                'Error! Please review workout circuits',
            })
          }

          this.request.$busy = false

          if (this.request.$getError(`circuits`)) {
            this.$refs.toast.open({
              color: 'red',
              message: this.request.$getError(`circuits`)[0],
            })
          }
        })
      }
    },

    async completeWorkout() {
      if (!(await this.confirmForZeroEstimateDuration())) return
      this.request.$busy = true
      this.request.$clearErrors()

      let saveOrUpdate =
        !this.isWorkoutCompleted &&
        this.workout.id &&
        !this.workoutCircuits.length
          ? this.saveWorkoutCircuits()
          : this.updateWorkoutCircuits()

      try {
        await saveOrUpdate

        if (!this.isWorkoutCompleted) {
          await this.updateWorkout()
        }

        this.updateWorkoutList({ complete: true, workout: this.workout })

        this.request.$timeout(() => {
          this.$router.replace({
            name: 'active.workouts',
          })
        })
      } catch ({ response }) {
        this.request.$timeout(() => {
          if (response && response.status === 422) {
            this.request.$setErrors(response.data.errors)

            this.$refs.toast.open({
              color: 'red',
              message:
                response.data.message ??
                'Error! Please review workout circuits',
            })
          }

          this.request.$busy = false

          if (this.request.$getError(`circuits`)) {
            this.$refs.toast.open({
              color: 'red',
              message: this.request.$getError(`circuits`)[0],
            })
          }
        })
      }
    },

    saveWorkoutCircuits() {
      return this.saveCircuits({
        workout: this.workout,
        circuits: this.workoutCircuits,
      })
    },

    updateWorkoutCircuits() {
      return this.updateCircuits({
        workout: this.workout,
        circuits: this.workoutCircuits,
      })
    },

    async updateWorkout() {
      await this.saveWorkout({
        ...this.workout,
        completed: 1,
      })
    },
  },

  watch: {
    workout(workout) {
      this.equipments = workout.equipments
    },

    'snackbar.open'(value) {
      if (!value) {
        this.snackbar = {
          msg: null,
          open: false,
          color: null,
        }
      }
    },
  },
}
</script>
