<template>
  <v-dialog v-model="show" width="800" scrollable persistent class="modal">
    <v-card class="modal--modal-card">
      <v-card-title class="primary--text d-flex align-center">
        <h3>Add New Exercise</h3>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="show = false" icon>
          <v-icon> {{ icons.remove }} </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-stepper class="elevation-1" v-model="step">
        <v-stepper-header class="elevation-0">
          <v-stepper-step :complete="step > 1" step="1">
            Exercise Details
          </v-stepper-step>

          <v-divider />

          <v-stepper-step :complete="step > 2" step="2">
            Exercise Steps
          </v-stepper-step>

          <v-divider />

          <v-stepper-step step="3"> Exercise Swaps </v-stepper-step>
        </v-stepper-header>

        <v-divider />
      </v-stepper>

      <v-card-text class="mx-0 pa-0 elevation-0" style="height: 600px">
        <v-stepper v-model="step">
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row>
                <v-col cols="12">
                  <exercise-details-form :form-data="form" />
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content class="elevation-0" step="2">
              <v-row>
                <v-col cols="12">
                  <exercise-instruction
                    :items="form.instructions"
                    :is-saving="form.$busy"
                  />
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-row>
                <v-col cols="12">
                  <swap-exercises
                    class="mb-7"
                    title="Regressions"
                    :type="1"
                    :items="exerciseRegressions"
                  />

                  <swap-exercises
                    class="mb-7"
                    title="Progressions"
                    :type="2"
                    :items="exerciseProgressions"
                  />

                  <swap-exercises
                    class="mb-7"
                    title="Swaps"
                    :type="3"
                    :items="exerciseSwaps"
                  />

                  <swap-exercises
                    class="mb-7"
                    title="No Equipments"
                    :type="4"
                    :items="exerciseNoEquipments"
                  />
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-divider />
      <v-card-actions class="pa-5">
        <v-spacer />
        <v-btn v-if="step > 1" text @click="goBack"> Back </v-btn>

        <v-btn
          v-if="step < 3"
          class="px-10 ml-5"
          color="primary"
          :loading="form.$busy"
          @click="saveAndContinue"
        >
          Continue
        </v-btn>

        <v-btn
          v-else
          class="px-10 ml-5"
          :loading="form.$busy"
          @click="attachExercise"
          color="primary"
        >
          Attach Exercise to Circuit
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.open"
      :timeout="2000"
      bottom
      right
    >
      {{ snackbar.msg }}
      <v-btn class="px-5" @click="snackbar.open = false" dark text>
        <span> Close </span>
      </v-btn>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ExerciseInstruction from '@/components/forms/exercises/ExerciseInstructions'
import ExerciseDetailsForm from '@/components/forms/exercises/ExerciseDetailsForm'
import SwapExercises from '@/components/forms/exercises/SwapExercises'
import Form from '@/utils/form'

import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { map, pick, extend } from 'lodash'
import { mdiClose } from '@mdi/js'

export default {
  name: 'AttachExerciseModal',

  components: {
    ExerciseInstruction,
    ExerciseDetailsForm,
    SwapExercises,
  },

  props: {
    value: {
      type: Boolean,
    },

    data: {
      type: Object,
    },

    attachTo: {
      type: Object,
    },
  },

  data() {
    return {
      step: 1,
      show: this.value,
      circuit: this.attachTo,
      exercise: this.data,
      form: new Form({
        id: null,
        completed: false,
        description: null,
        title: this.data.name,
        equipments: [],
        instructions: [],
        video_public_id: null,
        in_tutorial_library: false,
        tutorial_video_public_id: null,
      }),
      snackbar: {
        msg: null,
        open: false,
        color: null,
      },
      icons: {
        remove: mdiClose,
      },
    }
  },

  computed: {
    ...mapState({
      selectedExercise: (state) => state.exercises.selectedExercise,
    }),

    ...mapGetters({
      exerciseSwaps: 'exercises/getSelectedExerciseSwaps',
      exerciseRegressions: 'exercises/getSelectedExerciseRegressions',
      exerciseProgressions: 'exercises/getSelectedExerciseProgressions',
      exerciseNoEquipments: 'exercises/getSelectedExerciseNoEquipments',
    }),

    hasInstructions() {
      return !!this.form.instructions.length
    },
  },

  created() {
    this.circuit = this.attachTo
  },

  methods: {
    ...mapActions({
      saveExercise: 'exercises/saveExercise',
    }),

    ...mapMutations({
      setSelectedExercise: 'exercises/setSelectedExercise',
    }),

    saveAndContinue() {
      this.form.$busy = true
      this.form.$clearErrors()

      if (this.step === 1) {
        this.saveNewExercise()
      }

      if (this.step === 2) {
        if (!this.hasInstructions) {
          this.form.$busy = false
          this.snackbar.open = true
          this.snackbar.color = 'error'
          this.snackbar.msg = 'Instrucions are required.'

          return false
        }

        this.saveNewExercise()
      }
    },

    async saveNewExercise() {
      if (!this.form.id) {
        delete this.form.id
      }

      delete this.form.alternatives

      let equipments = map(this.form.equipments, (equipment) => {
        return typeof equipment === 'object' ? equipment.id : equipment
      })

      await this.saveExercise({ ...this.form, equipments })
        .then(({ data }) => {
          this.setSelectedExercise(data)

          this.form.$timeout(() => {
            this.step = this.step + 1
            this.form.$busy = false
          })
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },

    async attachExercise() {
      this.form.$busy = true
      this.form.completed = true

      let equipments = map(this.form.equipments, (equipment) => {
        return typeof equipment === 'object' ? equipment.id : equipment
      })

      await this.saveExercise({ ...this.form, equipments })
        .then(({ data }) => {
          this.form.$timeout(() => {
            this.form.$busy = false

            this.$emit('attach', {
              circuit: this.attachTo,
              exercise: data,
            })
          })
        })
        .catch(({ response }) => {
          this.form.$timeout(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },

    goBack() {
      this.step = this.step - 1
    },

    fillForm(exercise) {
      let data = pick(exercise, [
        'id',
        'title',
        'tempo',
        'equipments',
        'description',
        'instructions',
        'video_public_id',
        'in_tutorial_library',
        'tutorial_video_public_id',
      ])

      data.equipments = map(data.equipments, (equipment) => {
        return equipment.id
      })

      data.completed = !!exercise.completed_at

      this.form = new Form(extend(this.form, data))
    },
  },

  watch: {
    value(val) {
      this.show = val
    },

    show(val) {
      let form = new Form({
        id: null,
        completed: false,
        description: null,
        title: this.data.name,
        equipments: [],
        instructions: [],
        video_public_id: null,
        tutorial_video_public_id: null,
      })

      if (!val) {
        this.step = 1
        this.form = extend(this.form, form)
      }

      this.$emit('input', val)
    },

    'data.name'(val) {
      this.form.title = val
    },

    selectedExercise(value) {
      this.fillForm(this.selectedExercise)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-stepper {
  box-shadow: none !important;
}
</style>
