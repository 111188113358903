<template>
  <div class="bbr-workouts--new-workout-details">
    <v-row>
      <v-col lg="8" md="12">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Workout Details</h2>

          <v-btn
            class="mr-5 px-7"
            color="primary"
            :loading="form.$busy"
            @click="saveForLater"
            text
          >
            <span v-if="!form.completed">Save & Finish Later</span>
            <span v-else>Save & Close</span>
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            :loading="this.form.$busy"
            @click="saveAndContinue"
            depressed
            text
          >
            <span> Next </span>
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>

        <div class="bbr-wrokouts--details-form-container">
          <workout-details-form :form-data="form" />
        </div>
      </v-col>
    </v-row>

    <confirm-dialog ref="confirmDelete" persistent />
  </div>
</template>

<script>
import WorkoutDetailsForm from '../components/WorkoutDetailsForm'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import { mapActions, mapMutations, mapState } from 'vuex'
import { mdiPlus, mdiArrowRight, mdiContentDuplicate } from '@mdi/js'
import Event from '@/services/eventBus'
import Form from '@/utils/form'
import { pick, extend, map, find } from 'lodash'

export default {
  name: 'NewWorkoutDetails',

  components: {
    ConfirmDialog,
    WorkoutDetailsForm,
  },

  data() {
    return {
      icons: {
        add: mdiPlus,
        next: mdiArrowRight,
        duplicate: mdiContentDuplicate,
      },
      form: new Form({
        id: this.$attrs.id,
        tags: [],
        trainer: {},
        type: null,
        title: null,
        durations: [],
        category: null,
        is_liss: false,
        difficulty: null,
        trainer_id: null,
        description: null,
        completed: false,
        has_difficulty: false,
        is_workout_extra: false,
        training_programs: [],
        training_program_ids: null,
      }),
    }
  },

  computed: {
    ...mapState({
      selectedWorkout: (state) => state.workouts.selectedWorkout,
    }),

    isCompleted() {
      return !!this.selectedWorkout.completed_at
    },

    hasCircuits() {
      return !!this.selectedWorkout.circuits.length
    },

    isNewWorkout() {
      return this.$route.meta.new
    },

    canBeDuplicated() {
      return !!this.$attrs.id
    },
  },

  created() {
    if (this.selectedWorkout.id) {
      this.fillForm(this.selectedWorkout, 'created')
    }
  },

  methods: {
    ...mapActions({
      saveWorkout: 'workouts/saveWorkout',
      updateCircuits: 'workouts/updateWorkoutCircuitMultiple',
    }),

    ...mapMutations({
      updateWorkoutList: 'workouts/updateWorkoutList',
      setSelectedWorkout: 'workouts/setSelectedWorkout',
      convertCirctuisToBeginner: 'workouts/convertCirctuisToBeginner',
      clearSelectedWorkoutCircuits: 'workouts/clearSelectedWorkoutCircuits',
    }),

    async saveForLater() {
      this.form.$busy = true
      this.form.$clearErrors()

      try {
        let workout = await this.saveWorkout(this.form.$data())

        this.updateWorkoutList({
          complete: this.form.completed,
          workout: workout,
        })

        Event.$emit('new-workout-added', this.form.completed)

        this.form.$timeout(() => {
          this.form.$busy = false

          this.$router.push({
            name: this.isCompleted ? 'active.workouts' : 'draft.workouts',
          })
        })
      } catch ({ response }) {
        this.form.$timeout(() => {
          if (response && response.status === 422) {
            this.form.$setErrors(response.data.errors)
          }

          this.form.$busy = false
        })
      }
    },

    async saveAndContinue() {
      this.form.$busy = true
      this.form.$clearErrors()

      try {
        let workout = await this.saveWorkout(this.form.$data())

        this.updateWorkoutList({
          complete: this.form.completed,
          workout: workout,
        })

        this.form.$timeout(() => {
          this.form.$busy = false

          this.setSelectedWorkout(workout)

          this.$router.push({
            name: this.isCompleted
              ? 'workout.exercises'
              : 'new.workout.exercises',
            params: { id: workout.id },
          })
        })
      } catch ({ response }) {
        this.form.$timeout(() => {
          if (response && response.status === 422) {
            this.form.$setErrors(response.data.errors)
          }
          this.form.$busy = false
        })
      }
    },

    updateDurations() {
      let _workout = this.form
      _workout.durations = map(
        this.form.training_programs,
        (trainingProgram) => {
          const workoutDuration = find(this.form.workout_durations, [
            'training_program_id',
            trainingProgram.id,
          ])

          return {
            duration: null,
            training_program: trainingProgram,
            training_program_id: trainingProgram.id,
            ...workoutDuration,
          }
        }
      )

      this.form = extend(this.form, _workout)
    },

    next() {
      this.$router.push({ name: 'workouts' })
    },

    fillForm(workout, action) {
      let _workout = pick(workout, [
        'id',
        'type',
        'tags',
        'title',
        'is_liss',
        'trainer',
        'duration',
        'category',
        'trainer_id',
        'difficulty',
        'description',
        'completed_at',
        'has_difficulty',
        'is_workout_extra',
        'training_programs',
        'workout_durations',
      ])

      this.updateDurations()

      this.form.is_workout_extra = !!(
        workout.type &&
        workout.type &&
        workout.difficulty
      )

      _workout.duration = _workout.duration || {
        gym: null,
        home: null,
      }
      _workout.completed = !!_workout.completed_at

      this.form = extend(this.form, _workout)
    },

    async updateWorkoutCircuits() {
      let { data } = await this.saveWorkout(this.form)
      this.setSelectedWorkout(data)

      await this.updateCircuits({ workout: this.form, circuits: [] })
      await this.clearSelectedWorkoutCircuits()
    },
  },

  watch: {
    selectedWorkout: {
      handler(value) {
        this.fillForm(value, 'watcher')
      },
      deep: true,
    },

    async 'form.has_difficulty'(newValue, oldValue) {
      if (!newValue && oldValue && this.hasCircuits) {
        const confirm = await this.$refs.confirmDelete.open(
          'Remove Difficulty',
          `This action will remove all your existing workout circuits. <br>
          <b> Do you wish to continue? <b>`
        )

        if (confirm) {
          this.updateWorkoutCircuits()
        } else {
          this.form.has_difficulty = oldValue
        }
      }
    },

    'form.training_programs'(value) {
      this.updateDurations()
    },
  },
}
</script>
