<template>
  <div>
    <div class="mb-6">
      <v-btn
        color="primary"
        :loading="isSaving"
        @click="addNew = true"
        depressed
      >
        <v-icon class="mr-2"> {{ icons.add }} </v-icon>
        Add New Step
      </v-btn>

      <v-btn
        v-if="!onEdit && hasInstrucitons"
        class="ml-3"
        color="primary"
        :loading="isSaving"
        @click="editInstructions"
        text
      >
        <v-icon class="mr-3"> {{ icons.edit }} </v-icon>
        Edit Steps
      </v-btn>

      <v-btn v-if="onEdit" class="ml-3" @click="cancelEdit" depressed>
        Cancel Edit
      </v-btn>
    </div>

    <div v-if="addNew || !hasInstrucitons" class="mb-10 text-right">
      <v-textarea
        v-model="instruction"
        label="Add Instruction"
        rows="3"
        hide-details
        auto-grow
        outlined
      />

      <v-btn
        class="mt-4 mr-3"
        color="error"
        @click="addNew = false"
        depressed
        text
      >
        Cancel
      </v-btn>
      <v-btn
        class="mt-4 px-8 success"
        color="success"
        @click="addInstruction"
        dark
        depressed
      >
        Add Step
      </v-btn>
    </div>

    <div class="pt-6">
      <draggable
        tag="div"
        :list="instructions"
        :animation="180"
        :force-fallback="true"
        fallback-class="chosen"
        ghost-class="moving-instruction"
        group="exercise-instructions"
        handle=".grab-instruction"
      >
        <template v-for="(instruction, index) in instructions">
          <div :key="index">
            <v-divider v-if="index === 0" />
            <exercise-step
              :instruction-data="instruction"
              :index="index"
              :edit="onEdit"
              @instruction-changed="instructionChanged"
            >
              <template v-if="onEdit" v-slot:remove-button>
                <v-btn @click="removeInstruction(index)" icon>
                  <v-icon class="red--text">
                    {{ icons.remove }}
                  </v-icon>
                </v-btn>
              </template>

              <template v-if="draggable && onEdit" v-slot:drag-button>
                <v-btn class="grab-instruction" icon>
                  <v-icon> {{ icons.drag }} </v-icon>
                </v-btn>
              </template>
            </exercise-step>
            <v-divider />
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import { mdiClose, mdiDragVertical, mdiPlus, mdiPencil } from '@mdi/js'
import ExerciseStep from './ExerciseStep'
import draggable from 'vuedraggable'
import { clone } from 'lodash'

export default {
  name: 'ExerciseInstructions',

  components: {
    draggable,
    ExerciseStep,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    isSaving: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      addNew: false,
      onEdit: false,
      instruction: null,
      instructions: [],
      instructionEditCopy: [],
      icons: {
        add: mdiPlus,
        edit: mdiPencil,
        remove: mdiClose,
        drag: mdiDragVertical,
      },
    }
  },

  computed: {
    hasInstrucitons() {
      return !!this.instructions.length
    },

    draggable() {
      return this.instructions.length > 1
    },
  },

  created() {
    this.addNew = this.addNew
    this.instructions = this.items
  },

  methods: {
    addInstruction() {
      if (!this.instruction) return

      this.instructions.push(this.instruction)
      this.addNew = true
      this.instruction = null
    },

    editInstructions() {
      this.onEdit = true
      this.addNew = false

      this.instructionEditCopy = clone(this.instructions)
    },

    cancelEdit() {
      this.onEdit = false
      this.instructions = clone(this.instructionEditCopy)
      this.instructionEditCopy = []
    },

    removeInstruction(index) {
      this.instructions.splice(index, 1)
    },

    instructionChanged({ index, step }) {
      this.$set(this.instructions, index, step)
    },
  },

  watch: {
    items(values) {
      this.instructions = values
    },

    addNew(value) {
      this.addNew = value
    },

    isSaving(saving) {
      if (saving) {
        this.onEdit = false
        this.addNew = false
        this.instructionEditCopy = clone(this.instructions)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.moving-instruction {
  opacity: 0.1 !important;
  color: transparent !important;
  border: 3px dashed var(--v-primary-base) !important;
  background-color: var(--v-grey-base) !important;

  .instruction-input {
    opacity: 0 !important;
    color: transparent !important;
    background-color: var(--v-grey-base) !important;
  }
}

.sortable-drag {
  opacity: 1 !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  background-color: white !important;
  border: 1px solid var(--v-grey-base) !important;

  .v-divider {
    visibility: hidden !important;
  }
}
</style>
