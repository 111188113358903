<template>
  <div class="bbr-exercise--details-form">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <v-sheet
              class="d-inline-flex flex-wrap justify-space-between align-start full-width"
            >
              <v-checkbox
                v-model="form.in_tutorial_library"
                label="Include in tutorial library "
                color="primary"
              />
              <v-checkbox
                v-model="form.is_explainer_video"
                label="Explainer Video"
                color="primary"
                data-testid="is_explainer_checkbox"
              />
              <v-sheet />
            </v-sheet>
            <v-checkbox
              v-model="form.is_prenatal"
              label="Prenatal"
              color="primary"
            />
            <v-checkbox
              v-model="form.is_postnatal"
              label="Postnatal"
              color="primary"
            />
          </v-col>

          <v-col cols="3">
            <v-text-field
              v-model="form.code"
              label="Exercise Code"
              outlined
              flat
            />
          </v-col>

          <v-col cols="12" sm="5">
            <v-text-field
              v-model="form.title"
              :error-messages="form.$getError('title')"
              label="Exercise title"
              outlined
              flat
            />
          </v-col>

          <v-col cols="12" sm="4">
            <search-trainer-input
              v-model="form.trainer_id"
              label="Exercise Trainer"
              :error-messages="form.$getError('trainer_id')"
              :default-value="form.trainer"
              clearable
              outlined
              flat
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="form.description"
              :error-messages="form.$getError('description')"
              label="Exercise Notes"
              rows="4"
              auto-grow
              no-resize
              outlined
              flat
            />
          </v-col>

          <v-col cols="12">
            <search-equipment-chips
              v-model="form.equipments"
              label="Exercise Equipments"
              :error-messages="form.$getError('equipments')"
              outlined
              flat
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.video_public_id"
              label="Exercise Demonstration ID"
              outlined
              flat
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.tutorial_video_public_id"
              label="Exercise Tutorial ID"
              outlined
              flat
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SearchEquipmentChips from '@/components/elements/equipments/SearchEquipmentChips'
import SearchTrainerInput from '@/components/elements/trainers/TrainerSearchInput'
import Form from '@/utils/form'

import { mdiPlus, mdiClose, mdiPaperclip } from '@mdi/js'
import { mapGetters } from 'vuex'

export default {
  name: 'ExerciseDetailsForm',

  components: {
    SearchEquipmentChips,
    SearchTrainerInput,
  },

  props: {
    formData: {
      type: Form,
      required: true,
    },
    chips: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      drag: false,
      videoFile: [],
      form: this.formData,
      isDetailsDone: false,
      icons: {
        add: mdiPlus,
        remove: mdiClose,
        file: mdiPaperclip,
      },
    }
  },

  computed: {
    ...mapGetters({
      exerciseEquipments: 'exercises/getSelectedExerciseEquipments',
    }),

    hasVideoFile() {
      return !!this.videoFile.length
    },
  },

  watch: {
    formData() {
      this.form = this.formData
    },
  },
}
</script>

<style lang="scss" scoped>
.moving-card {
  opacity: 0.1 !important;
  cursor: grab !important;
  color: var(--v-grey-base) !important;
  border: 1px solid var(--v-grey-base) !important;
  background-color: var(--v-grey-base) !important;

  .v-card__text {
    visibility: hidden !important;
  }
}

.chosen {
  opacity: 1 !important;
  cursor: grabbing !important;
  background-color: white !important;
  border: 1px sold var(--v-primary-base) !important;

  .v-card__text {
    border-radius: 4px !important;
    border: 1px solid var(--v-grey-base) !important;
  }

  .v-divider {
    opacity: 0 !important;
  }
}

.video-container {
  min-height: 450px;
}
</style>
