<template>
  <div class="bbr-workouts--workout-circuit-form">
    <v-row class="d-flex align-center my-5">
      <v-col cols="12" sm="8">
        <div class="d-flex align-center">
          <h3>
            <span v-if="venue === 1"> Gym </span>
            <span v-else> Home </span>
            Workout Sections
          </h3>
        </div>
      </v-col>

      <v-col cols="12" sm="4">
        <v-select
          v-if="hasDifficulty"
          v-model="difficulty"
          :items="difficulties"
          placeholder="Difficulty"
          item-text="name"
          item-value="id"
          hide-details
          outlined
          flat
        />
      </v-col>
    </v-row>

    <v-row v-if="filteredCircuitEquipments.length" class="mt-2">
      <v-col>
        <v-chip
          v-for="equipment in filteredCircuitEquipments"
          :key="equipment.id"
          class="mr-2 mb-1"
          color="primary"
        >
          {{ equipment.name }}
        </v-chip>
        <v-divider class="mt-5" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" v-if="filteredCircuits.length && !loading">
        <template v-for="(circuit, circuitIndex) in circuits">
          <circuit-form
            v-show="showCircuit(circuit)"
            :item="circuit"
            :key="circuitIndex"
            :request-bag="request"
            :is-liss="isLissCardioWorkout"
            :has-one-circuit="hasOnefilteredCircuit"
            @delete="removeCircuit(circuit)"
            @removeExercise="removeExercise"
            @addCreatedExercise="addNewExercise"
            @selectedExercise="addNewCircuitExercise"
          />
        </template>
      </v-col>

      <v-col v-else class="py-12 mt-2" style="position: relative">
        <v-overlay
          class="mx-3 py-12 rounded-lg"
          :opacity="0.2"
          :value="loading"
          absolute
        >
          <v-progress-circular indeterminate size="64" />
        </v-overlay>

        <no-list :details="loading ? ' ' : 'No Circuits List'" no-image />
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-col>
        <v-btn @click="addNewCircuit" depressed>
          <v-icon class="mr-3"> {{ icons.add }} </v-icon>
          Add New Section
        </v-btn>
      </v-col>
    </v-row>

    <attach-exercise-modal
      v-model="showAddNewExercise"
      :data="addedCircuitExercise"
      :attach-to="attachToCircuit"
      @attach="attachCircuit"
    />
  </div>
</template>

<script>
import AttachExerciseModal from '@/components/modals/exercise/AttachExercise'
import NoList from '@/components/elements/NoList'
import CircuitForm from './CircuitForm'
import Form from '@/utils/form'

import { mdiPlus, mdiTrashCanOutline, mdiClose, mdiArrowRight } from '@mdi/js'
import { map, uniqBy, filter, flatMap, findIndex } from 'lodash'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { mask } from 'vue-the-mask'

export default {
  name: 'WorkoutCircuitCard',

  components: {
    NoList,
    CircuitForm,
    AttachExerciseModal,
  },

  directives: {
    mask,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    venue: {
      type: Number,
      required: true,
    },
    request: {
      type: Form,
    },
  },

  data() {
    return {
      circuits: [],
      search: null,
      difficulty: 1,
      loading: false,
      isWorkout: false,
      showAddNewExercise: false,
      attachToCircuit: {},
      addedCircuitExercise: {
        name: null,
      },
      icons: {
        add: mdiPlus,
        remove: mdiClose,
        next: mdiArrowRight,
        delete: mdiTrashCanOutline,
      },
      difficulties: [
        { id: 1, name: 'Beginner' },
        { id: 2, name: 'Intermediate' },
        { id: 3, name: 'Advanced' },
      ],
    }
  },

  computed: {
    ...mapState({
      selectedWorkout: (state) => state.workouts.selectedWorkout,
    }),

    ...mapGetters({
      workoutCircuits: 'workouts/getSelectedWorkoutCircuits',
    }),

    isLissCardioWorkout() {
      return this.selectedWorkout.is_liss
    },

    hasDifficulty() {
      return !!this.selectedWorkout.has_difficulty
    },

    workoutOverDuration() {
      return this.workoutDuration / 60
    },

    filteredCircuits() {
      if (!this.hasDifficulty) {
        return this.circuits
      }

      return filter(this.circuits, (circuit) => {
        return circuit.difficulty === this.difficulty
      })
    },

    hasfilteredCircuits() {
      return !!this.filteredCircuits.length
    },

    hasOnefilteredCircuit() {
      return this.filteredCircuits.length === 1
    },

    filteredCircuitEquipments() {
      let _equipments = flatMap(this.filteredCircuits, (circuit) => {
        return flatMap(circuit.exercises, (exercise) => {
          return map(exercise.equipments, (equipment) => {
            return {
              id: equipment.id,
              name: equipment.name,
              venue: circuit.venue,
              uniqness: `${equipment.id}-${this.venue}`,
            }
          })
        })
      })

      return uniqBy(_equipments, 'uniqness')
    },
  },

  created() {
    this.circuits = this.items
  },

  updated() {
    this.circuits = this.items
  },

  methods: {
    ...mapMutations({
      addCircuit: 'workouts/addNewCircuit',
      deleteCircuit: 'workouts/removeWorkoutCircuit',
      deleteExercise: 'workouts/removeExerciseOnCircuit',
    }),

    addNewCircuit() {
      this.addCircuit({
        id: null,
        rounds: 0,
        rest: null,
        name: null,
        exercises: [],
        set_rest: null,
        duration: null,
        venue: this.venue,
        is_emom: false,
        computed_duration: null,
        estimated_duration: null,
        difficulty: this.difficulty,
        order: this.workoutCircuits.length + 1,
      })
    },

    addNewCircuitExercise({ circuit, exercise }) {
      let index = findIndex(this.circuits, { order: circuit.order })
      let circuitExersises = this.circuits[index]['exercises']

      circuitExersises.push({
        type: 2,
        rounds: 0,
        rest: null,
        sets: null,
        reps: null,
        tempo: null,
        seconds: null,
        duration: null,
        set_type: null,
        switch_type: null,

        data: exercise,
        venue: this.venue,
        name: exercise.title,
        exercise_id: exercise.id,
        equipments: exercise.equipments,
        order: circuit.exercises.length + 1,
      })
    },

    removeCircuit(circuit) {
      this.deleteCircuit({ order: circuit.order })
    },

    removeExercise({ circuit, exerciseIndex }) {
      this.deleteExercise({ circuit, exerciseIndex })
    },

    addNewExercise({ circuit, exercise }) {
      this.attachToCircuit = circuit
      this.addedCircuitExercise.name = exercise
      this.showAddNewExercise = true
    },

    attachCircuit(attachment) {
      this.addNewCircuitExercise(attachment)

      this.showAddNewExercise = false
      this.addedCircuitExercise.name = null
    },

    showCircuit(circuit) {
      if (this.hasDifficulty) {
        return (
          circuit.difficulty === this.difficulty && circuit.venue === this.venue
        )
      } else {
        return circuit.venue === this.venue
      }
    },

    async loadCircuits() {
      this.loading = true

      await this.$sleep(500)

      this.loading = false
    },
  },

  watch: {
    items(values) {
      this.circuits = values
    },

    venue() {
      this.loadCircuits()
    },
  },
}
</script>

<style lang="scss">
.bbr-workouts {
  &--exercise-list--item-container {
    &:nth-child(odd) {
      .exercise-list--item {
        background-color: var(--v-primary-lighten4) !important;
      }
    }

    &:nth-child(even) {
      .exercise-list--item {
        background-color: var(--v-grey-lighten3) !important;
      }
    }
  }

  &--workout-circuits-card {
    border-radius: 15px !important;

    .v-card__title {
      border-top-left-radius: 15px !important;
      border-top-right-radius: 15px !important;
    }
  }
}
</style>
