<template>
  <v-card class="bbr-workouts--workout-circuits-card mb-10" outlined>
    <v-card-title class="pt-2 pb-2 grey lighten-2">
      <v-row class="d-flex align-center text-right">
        <v-col v-if="!isLiss" cols="6">
          <v-checkbox label="Is EMOM Circuit?" v-model="circuit.is_emom" />
        </v-col>

        <v-col v-if="isEmomCircuit && !isLiss" cols="4">
          <v-text-field
            v-model="circuit.emom_duration"
            label="EMOM Duration"
            :error-messages="
              request.$getError(`circuits.${circuit.order - 1}.emom_duration`)
            "
            :hide-details="
              !request.$getError(`circuits.${circuit.order - 1}.emom_duration`)
            "
            outlined
            flat
          />
        </v-col>

        <v-spacer v-if="isLiss" />

        <v-col :cols="isEmomCircuit ? 2 : 6" class="text-right">
          <v-btn color="secondary" @click="removeCircuit(circuit)" text>
            Delete Circuit
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="circuit.name"
            label="Section Name"
            :error-messages="
              request.$getError(`circuits.${circuit.order - 1}.name`)
            "
            :hide-details="
              !request.$getError(`circuits.${circuit.order - 1}.name`)
            "
            outlined
            flat
          />
        </v-col>

        <v-col cols="12" sm="2">
          <v-text-field
            v-model="circuit.duration"
            v-mask="'########'"
            label="Duration"
            :error-messages="
              request.$getError(`circuits.${circuit.order - 1}.duration`)
            "
            :hide-details="
              !request.$getError(`circuits.${circuit.order - 1}.duration`)
            "
            outlined
            flat
          />
        </v-col>

        <v-col cols="12" sm="2">
          <v-text-field
            v-model="circuit.estimated_duration"
            v-mask="'########'"
            label="Computed Duration"
            :error-messages="
              request.$getError(
                `circuits.${circuit.order - 1}.estimated_duration`
              )
            "
            :hide-details="
              !request.$getError(
                `circuits.${circuit.order - 1}.estimated_duration`
              )
            "
            readonly
            outlined
            flat
          />
        </v-col>

        <v-col cols="12" sm="2">
          <v-text-field
            v-model="circuit.rest"
            v-mask="'########'"
            label="Section Rest"
            hide-details
            outlined
            flat
          />
        </v-col>

        <v-col cols="12" sm="2" class="d-flex">
          <v-text-field
            v-model="circuit.set_rest"
            v-mask="'########'"
            label="Set Rest"
            hide-details
            outlined
            flat
          />
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-card-text
      class="bbr-workouts--exercise-list--item-container pa-0"
      v-for="(exercise, exerciseIndex) in circuit.exercises"
      :key="exerciseIndex"
    >
      <circuit-exercises
        class="px-4 pb-5 exercise-list--item"
        :item="exercise"
        :request="request"
        :circuit-index="circuit.order - 1"
        :exercise-index="exerciseIndex"
        :selected-exercises="circuit.exercises"
        @delete="removeExercise(circuit, exerciseIndex)"
        @changeOrder="swapOrder"
      />

      <v-row :key="`exercise-${exerciseIndex}`" no-gutters>
        <v-col> <v-divider /> </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="px-4">
      <v-row>
        <v-col cols="12" sm="6">
          <search-exercise
            label="Search to Add Exercise"
            :errors="
              request.$getError(`circuits.${circuit.order - 1}.exercises`)
            "
            :trainer="workout.trainer"
            @selected="addNewCircuitExercise(circuit, $event)"
            @add-new="addNewExercise(circuit, $event)"
            with-equipments
            clear-onselect
            no-data-button
            return-object
            with-trainer
            completed
          />
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import SearchExercise from '@/components/elements/exercises/SearchExercisesInput'
import CircuitExercises from './CircuitExerciseForm'
import Form from '@/utils/form'

import { get, sum, map, max, each, some, chunk, flatten } from 'lodash'
import { mask } from 'vue-the-mask'
import { mapState } from 'vuex'

export default {
  name: 'WorkoutCircuitForm',

  components: {
    SearchExercise,
    CircuitExercises,
  },

  directives: {
    mask,
  },

  props: {
    item: Object,
    isLiss: Boolean,
    requestBag: Form,
    hasOneCircuit: Boolean,
  },

  data() {
    return {
      circuit: this.item,
      request: this.requestBag,
    }
  },

  created() {
    this.calculateCircuitDuration()
  },

  computed: {
    ...mapState({
      workout: (state) => state.workouts.selectedWorkout,
    }),

    hasExercises() {
      return !!this.circuit?.exercises.length
    },

    isEmomCircuit() {
      return this.circuit.is_emom
    },
  },

  methods: {
    removeCircuit(circuit) {
      this.$emit('delete', circuit)
    },

    addNewCircuitExercise(circuit, exercise) {
      this.$emit('selectedExercise', { circuit, exercise })
    },

    addNewExercise(circuit, exercise) {
      this.$emit('addCreatedExercise', { circuit, exercise })
    },

    removeExercise(circuit, exerciseIndex) {
      this.$emit('removeExercise', { circuit, exerciseIndex })
    },

    calculateTotalRest(circuitExercises) {},

    calculateCircuitDuration() {
      let circuitRest = Number(this.circuit.rest)
      let circuitSetRest = Number(this.circuit.set_rest)

      let collectedExerciseRest = []
      let collectedExerciseDuration = []

      let isLissCardio = this.isLiss
      let hasOneCircuit = this.hasOneCircuit
      let circuitExercises = this.circuit.exercises
      let isWorkoutSection = some(circuitExercises, ['type', 1])

      map(circuitExercises, (exercise) => {
        // Some values/propeties are in text data type
        // It should be converted to number type for calculation
        let rest = Number(get(exercise, 'rest', 0))
        let sets = Number(get(exercise, 'sets', 0))
        let rounds = Number(get(exercise, 'rounds', 0))
        let duration = Number(get(exercise, 'duration', 0))

        // This is for plotting of rest per exercise
        // to create arrays of repetition of exercises
        let reps = sets || rounds || 1

        let isCircuit = exercise.type === 2
        let hasSwitch = !!exercise.switch_type
        let switchMultiplier = hasSwitch ? 2 : 1

        // Create rest depending no. of repetetion
        let restFiller = Array(reps).fill(rest)

        // Duration are calculated when exercises are circuits
        // and when a section is circuit, duration will be disregarded
        // if when there is workout exercise on the list of exercises on the section
        if (isCircuit && !isLissCardio) {
          // For calculation of exercise duration
          let totalDuration = duration * switchMultiplier * rounds
          collectedExerciseDuration.push(totalDuration)
        }

        // Create a new array for exercises that has switch types
        // by number of repetition and the switch count of 2
        if (switchMultiplier > 1) {
          let possibleExerciseRests = Array(reps * switchMultiplier).fill(rest)

          // Chunk collated rest switches by 2's
          restFiller = chunk(possibleExerciseRests, switchMultiplier)
        }

        // Collating rest per exercise
        collectedExerciseRest.push(restFiller)

        return exercise
      })

      // Get the longest rounds or rest in the circtuit
      // This will help braid the collected rest on the exercises loop
      // prettier-ignore
      let longestSetsAndRounds = max(map(circuitExercises, exr => { return Number(exr.rounds || exr.sets) }))

      // prettier-ignore
      let plottedExercises = map([...Array(longestSetsAndRounds).keys()], index => {
        let collected = []

        each(collectedExerciseRest, rest => { if (rest[index]) { collected.push(rest[index]) } })

        collected = flatten(collected).pop()

        return collected
      })

      let overAllSectionRests = hasOneCircuit ? 0 : circuitRest
      let overAllExerciseRests = sum(flatten(plottedExercises))

      // prettier-ignore
      let overAllSectionSetRests = Array(longestSetsAndRounds).fill(circuitSetRest)

      overAllSectionSetRests.pop()

      // prettier-ignore
      let estimatedDuration = !isWorkoutSection ? sum(collectedExerciseDuration) : 0 || 0

      // prettier-ignore
      this.circuit.estimated_duration = sum([estimatedDuration, overAllExerciseRests, sum(overAllSectionSetRests), overAllSectionRests])
    },
    swapOrder({ newOrder, oldOrder, index: currentIndex }) {
      const exercise = this.item.exercises
        .filter((exercise, i) => i !== currentIndex)
        .find((exercise) => exercise.order === newOrder)
      if (exercise) exercise.order = oldOrder
    },
  },

  watch: {
    item(values) {
      this.circuit = values
    },

    requestBag(values) {
      this.requestBag = values
    },

    circuit: {
      deep: true,
      handler(circuit) {
        this.calculateCircuitDuration()

        if (!circuit.is_emom) {
          this.circuit.emom_duration = null
        }
      },
    },

    hasOneCircuit: {
      deep: true,
      handler(circuit) {
        this.calculateCircuitDuration()
      },
    },
  },
}
</script>
